// Members.js

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../App.css'
import { getKioskStats } from '../services';
import { getMembers } from '../services';

import LoadingDivLayer  from './styled/LoadingDivLayer';
import BigStat from './styled/BigStat';
import MembersTable from './styled/MembersTable';
import TypeAheadSearch from './styled/TypeAheadSearch';

import { Title, EZSpacer, ContentWrapper } from './styled/title';

function sortMembersByRecentScans(membersArray) {
  membersArray.sort(function(a,b){
    return b.lastAssessmentDate - a.lastAssessmentDate;
  });

  return membersArray;
}

function Members() {
  const [members, setMembers] = useState([]);
  const [memberStats, setMemberStats] = useState([]);
  const [stats, setKioskStats] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const mounted = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    mounted.current = true;
    if(members.length) {
      return;
    }
    getMembers()
      .then(membersResp => {
        if(mounted.current) {
          let items = membersResp.members;
          setMembers(items);
          setMemberStats(membersResp.memberStats);
          setLoaded(true); // arbitrary choice of this api call as longest
        }
      })
  }, [members])

  useEffect(() => {
    if(stats.thisWeekAssessmentsCount) {
      return;
    }
    getKioskStats()
    .then(item => {
      if(mounted.current) { // TODO: how is this useful?
        setKioskStats(item);
        // setLoaded(true); // arbitrary choice of this api call as longest
      }
    })
  }, [stats])

  function handleMemberClick(memberid) {
    navigate('/history?from=Members&memberid=' + memberid);
  }

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper className="wrapper" loaded={loaded}>
        {/* <EZSpacer height={"8vh"} /> */}
        <BigStat 
          value={memberStats.totalUsers} 
          title='Members'>
        </BigStat>
        <BigStat 
          value={memberStats.activeUsers} 
          title='Active Members' 
          subtitle='last 10 days'>
        </BigStat>
        <BigStat 
          value={stats.thisWeekAssessmentsCount} 
          title='Assessments This Week'> 
        </BigStat>
        <EZSpacer height={"5vh"} />
        <TypeAheadSearch data={members} />
        <EZSpacer height={"4vh"} /> 
        <Title style={{ display: "inline-block", marginRight: "2.5vw" }}>Member List By Most Recent Activity</Title> 
        <EZSpacer height={"2vh"} /> 
        <MembersTable members={sortMembersByRecentScans(members)} handleClick={handleMemberClick} />
      </ContentWrapper>
    </div>
  );
}

export default Members;