// Overview.js

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css'

import { getKioskStats } from '../services';
import { getMembers } from '../services';
// import { getKioskAssessmentCounts } from '../services/assessments';

import { Title, EZSpacer, ContentWrapper } from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import BarGraph from './styled/BarGraph';
import BigStat from './styled/BigStat';
import MembersTable from './styled/MembersTable';

function sortMembersByScanCount(membersArray) {
  membersArray.sort(function(a,b){
    return b.AssessmentsCountAllTime - a.AssessmentsCountAllTime;
  });

  return membersArray;
}


function Overview() {
  const [members, setMembers] = useState([]);
  const [memberStats, setMemberStats] = useState([]);
  const [stats, setKioskStats] = useState([]);
  const [loaded, setLoaded ] = useState(false);
  const mounted = useRef(true);
  const navigate = useNavigate();

  useEffect(() => { 
    mounted.current = true; // TODO: how is this useful?
    if(members.length > 1) {
      return;
    }
    getMembers()
    .then(membersResp => {
      if(mounted.current) {
        let items = membersResp.members;
        setMembers(items);
        setMemberStats(membersResp.memberStats);
        // setLoaded(true); // arbitrary choice of this api call as longest
      }
    })
  }, [members])

  useEffect(() => {
    if(stats.thisWeekAssessmentsCount) {
      return;
    }
    getKioskStats()
    .then(item => {
      if(mounted.current) { // TODO: how is this useful?
        setKioskStats(item);
        setLoaded(true); // arbitrary choice of this api call as longest
      }
    })
  }, [stats])

  function handleMemberClick(memberid) {
    navigate('/history?from=Overview&memberid=' + memberid);
  }
  
  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper className="wrapper" loaded={loaded}>
        {/* <EZSpacer height={"13vh"} /> */}
        <BigStat 
          value={memberStats.totalUsers} 
          title='Members' 
          subValue={memberStats.newUsers > 0 ? '+' + memberStats.newUsers : '-' + memberStats.newUsers}
          subtitle='since last week'>
        </BigStat>
        <BigStat 
          value={stats.thisWeekAssessmentsCount} 
          title='Assessments This Week' 
          subValue={stats.lastWeekAssessmentsCount}
          subtitle='by this day last week'>
        </BigStat>
        <EZSpacer height={"3vw"} />
        <Title>Assessments taken over the past 30 Days</Title>
        <BarGraph data={stats.last30daysAssessmentCounts} />
        <EZSpacer height={"6vw"} />
        <Title>Top Members</Title>
        <EZSpacer height={"2vw"} />
        <MembersTable members={sortMembersByScanCount(members)} handleClick={handleMemberClick} />
      </ContentWrapper>
    </div>
  );
}

export default Overview;