// App.js

import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive'

import Login from './components/Login';

import SideBar from './components/styled/SideBar';
import Content from './components/styled/Content';

import { storageAvailable } from './helpers'; 

function App() {
  // const [loading, setLoading] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const deviceInfo = {
    isDesktopOrLaptop: isDesktopOrLaptop,
    isBigScreen: isBigScreen,
    isTabletOrMobile: isTabletOrMobile,
    isPortrait: isPortrait,
    isRetina: isRetina
  };

  // also have to change this in Login.js to test case where no localStorage
  // constlocalStorageExists = false; // use App.state as a backup if no localStorage?
  const localStorageExists = storageAvailable('localStorage');
  // const alreadyReloaded = localStorageExists && localStorage.getItem('alreadyReloaded');
  
  function storeToken(token_string) {
    localStorage.setItem('token', token_string);
  }

  function logout() {
    if (localStorageExists) {
      localStorage.setItem('token', null);
      localStorage.setItem('alreadyReloaded', false);
      window.location.reload();
    } else {
      setToken(null);
    }
  }

  let token = localStorage.getItem('token');
  let setToken = storeToken;

  // no way found yet to show a message only once per session if there is no localStorage,
  // so we are not giving the user a 'get a better browser' warning in that case :-(
  if (!localStorageExists) {  
    [token, setToken] = useState();
  }
  
  if(!token || token.length < 10) {
    return <Login setToken={setToken} />
  }

  return (
    <Router>
      <div style={{ display: "flex", minHeight: "100%"}}>
        <SideBar logout={logout} deviceInfo={deviceInfo} />
        <Content deviceInfo={deviceInfo} />
      </div>
    </Router>
  );
}

export default App;

