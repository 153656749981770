// Assessment.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import { format } from 'date-fns';

import { getMember } from '../services/members';
import { getAssessmentPageData } from '../services/assessments';

import TopLineScores from './styled/TopLineScores';
import BodyMap from './styled/BodyMap';
import PrimaryIssuesTable from './styled/PrimaryIssuesTable';
import SubDomainScoresTable from './styled/SubDomainScoresTable';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import { EZSpacer, BackLink, ContentWrapper, TabButton } from './styled/title';
import './Assessment.css';

const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;

function Assessment() {
  const navigate = useNavigate();
  const [issues, setIssues] = useState([]);
  const [scores, setScores] = useState([]);
  const [member, setMember] = useState({});
  const [alreadyFetched, setalreadyFetched] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState("mobility");
  // const mounted = useRef(true);

  let queryString = document.location.search;
  let params = new URLSearchParams(queryString);
  let assessmentId = params.get("assessmentid");
  let priorAssessmentId = params.get("prior");
  const backPage = params.get("from") ? params.get("from") : "Back";
  const backText = "< " + backPage;

  useEffect(() => {
    if(alreadyFetched) { // was (scores && scores[0])
      return;
    }
    getAssessmentPageData(assessmentId, priorAssessmentId)
      .then(item => {
        // if(mounted.current) { // TODO: how is this useful?
        // console.log("~~~~~ getAssessmentPageData() issuesData.length: " + item.issuesData.length);  
        setIssues(item.issuesData);
        setScores(item.scoresData);
        setalreadyFetched(true); 
        return item.metaData.memberid 
        // }
        })
      .then(memberid => {
        getMember(memberid)
        .then(item => {
          // if(mounted.current) { // TODO: how is this useful?
            setMember(item)
            setTimeout(() => { // TODO: is delay necessary?
              // console.log("Delayed for 2 seconds.");
              setLoaded(true);
            }, 1000)
          // }
        })
      })

    // return () => mounted.current = false; // TODO: how is this useful?
  }, [scores, issues])

  function setActiveTab(title) {
    console.log("~~~~~ setActiveTab() - title: " + title);
    setCurrentTab(title);
  }

  let rawDisplayDate = issues && issues[0] && issues[0].dateTime ?
    issues[0].dateTime :
    '05/17/2022 10:10:10';

  // const displayDate = format(new Date(rawDisplayDate), 'MM/dd/yyyy HH:mm:ss');
  const displayDate = rawDisplayDate; // TODO: pick a display style for dates & times!

  return (
    <div>
      {loaded ? '' : <LoadingDivLayer />}
      <ContentWrapper className="wrapper" loaded={loaded}>
        <BackLink onClick={() => navigate(-1)}>{backText}</BackLink>
        <h1>{member ? member.email : 'Unknown Member'}</h1>
        <EZSpacer height={"3vw"} />
        <h5>Assessment Performed {displayDate}</h5>
        <TopLineScores data={scores} />
        <EZSpacer height={"3vw"} />
        <TabButton tabTitle={"mobility"} onClick={() => setActiveTab("mobility")} currentTab={currentTab}>MOBILITY</TabButton>
        <TabButton tabTitle={"stability"} onClick={() => setActiveTab("stability")} currentTab={currentTab}>{isStretchLab ? "ACTIVATION" : "STABILITY"}</TabButton>
        <TabButton tabTitle={"posture"} onClick={() => setActiveTab("posture")} currentTab={currentTab}>POSTURE</TabButton>
        <TabButton tabTitle={"symmetry"} onClick={() => setActiveTab("symmetry")} currentTab={currentTab}>SYMMETRY</TabButton>
        <br />
        <EZSpacer height={"2vw"} />
        <BodyMap data={issues} domain={currentTab} maxIssues={3} style={{ marginLeft: "5vw" }} />
        <EZSpacer height={"2vw"} />
        <PrimaryIssuesTable data={issues} maxIssues={3} domain={currentTab} />
        <EZSpacer height={"2vw"} />
        <SubDomainScoresTable data={scores} domain={currentTab} />
        <EZSpacer height={"2vw"} />
      </ContentWrapper>
    </div>
  );
}

export default Assessment;